import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Row, Col } from "react-bootstrap"
import $ from "jquery"

import {
  VALUATION_PAGE_URL,
  BRANCHES_PAGE_URL,
  ABOUT_PAGE_URL,
  PROPERTY_SERVICES_PAGE_URL,
  AREA_GUIDE_PAGE_URL,
  LATEST_NEWS_PAGE_URL,
  PROPERTY_MANAGEMENT_PAGE_URL,
  TESTIMONIALS_PAGE_URL,
  MORTGAGES_PAGE_URL,
} from "../../common/site/constants"

const PopularSearchDynamic = props => {
  //Get values from refine search
  const [ptype, setPtype] = useState(false)
  const [searchbed, setsearchbed] = useState(false)

  var searchaction = ""
  var URL = ""
  var ptypeURL = ""
  var bedURL = ""
  var bedtypeText = ""
  var area = "Billingshurst, West Sussex"
  var typeText = "Property"
  var searcharea = ""
  var searchareaText = ""
  var slug_text = ""


  const removeSpecialChar = (val, symbol = '-') => {
    if (val) {
      return val.replace(/[^\w\s]/gi, ' ').replace(/\s\s+/g, ' ').replace(/ /gi, symbol).toLowerCase()
    }
    return val;
  }


  function capitalizeFirstLetter(string) {
    var str = removeSpecialChar(string, ' ')
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
  if (props.searchtype == "sales") {
    searchaction = "for Sale"
    URL = "/property/for-sale/"
  }
  if (props.searchtype == "lettings") {
    searchaction = "to Rent"
    URL = "/property/to-rent/"
  }
  if (props.searchtype == "newhomesales") {
    searchaction = "for Sale"
    URL = "/property/new-homes/for-sale/"
    typeText = "New homes"
  }
  if (props.searchtype == "newhomerentals") {
    searchaction = "to Rent"
    URL = "/property/new-homes/to-rent/"
    typeText = "New Property"
  }
  if (props.searchPtype) {
    ptypeURL = "/type-" + props.searchPtype
    typeText = capitalizeFirstLetter(props.searchPtype).replace(
      "Buy-to-let-investments",
      "Buy to let investments"
    )
  }
  if (props.searchBedroomfield) {
    bedtypeText = props.searchBedroomfield + " Bedroom " + typeText
    bedURL = "/" + props.searchBedroomfield + "-and-more-bedrooms"
  }
  //console.log("$$$$", props.Searcharea && props.Searcharea.areas !='undefined' && props.Searcharea.areas)
  if (props.Searcharea && props.Searcharea != "undefined") {
    searcharea = props.Searcharea.replace("in ", "").replace(",", "")
    searchareaText = props.Searcharea.replace(/%20/g, " ").replace("in ", "")
    slug_text = searcharea.replace(/\s/g, "-")
  }
  return (
    <Row>
      {props.searchBedroomfield ? (
        <Col md={6} lg={3} className="properties-info">
          <ul>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", "eastergate-west-sussex")
                  localStorage.setItem("searchValueFull", "eastergate-west-sussex")
                }}
                className="footer_scroll_top"
                href={`${URL}in-eastergate-west-sussex${ptypeURL}${bedURL}/radius-3-miles/`}
              >
                {bedtypeText} {searchaction} in Eastergate
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", "brighton-east-sussex")
                  localStorage.setItem("searchValueFull", "brighton-east-sussex")
                }}
                className="footer_scroll_top"
                href={`${URL}in-brighton-east-sussex${ptypeURL}${bedURL}/radius-3-miles/`}
              >
                {bedtypeText} {searchaction} in Brighton
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", "chichester-west-sussex")
                  localStorage.setItem("searchValueFull", "chichester-west-sussex")
                }}
                className="footer_scroll_top"
                href={`${URL}in-chichester-west-sussex${ptypeURL}${bedURL}/radius-3-miles/`}
              >
                {bedtypeText} {searchaction} in Chichester
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", "guildford-surrey")
                  localStorage.setItem("searchValueFull", "guildford-surrey")
                }}
                className="footer_scroll_top"
                href={`${URL}in-guildford-surrey${ptypeURL}${bedURL}/radius-3-miles/`}
              >
                {bedtypeText} {searchaction} in Guildford
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", "dorking-surrey")
                  localStorage.setItem("searchValueFull", "dorking-surrey")
                }}
                className="footer_scroll_top"
                href={`${URL}in-dorking-surrey${ptypeURL}${bedURL}/radius-3-miles/`}
              >
                {bedtypeText} {searchaction} in Dorking
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", "crawley-west-sussex")
                  localStorage.setItem("searchValueFull", "crawley-west-sussex")
                }}
                className="footer_scroll_top"
                href={`${URL}in-crawley-west-sussex${ptypeURL}${bedURL}/radius-3-miles/`}
              >
                {bedtypeText} {searchaction} in Crawley
              </a>
            </li>
          </ul>
        </Col>
      ) : (
        <Col md={6} lg={3} className="properties-info">
          <ul>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", "eastergate-west-sussex")
                  localStorage.setItem("searchValueFull", "eastergate-west-sussex")
                }}
                className="footer_scroll_top"
                href={`${URL}in-eastergate-west-sussex${ptypeURL}${bedURL}/radius-3-miles/`}
              >
                {typeText} {searchaction} in Eastergate
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", "brighton-east-sussex")
                  localStorage.setItem("searchValueFull", "brighton-east-sussex")
                }}
                className="footer_scroll_top"
                href={`${URL}in-brighton${ptypeURL}${bedURL}/radius-3-miles/`}
              >
                {typeText} {searchaction} in Brighton
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", "chichester-west-sussex")
                  localStorage.setItem("searchValueFull", "chichester-west-sussex")
                }}
                className="footer_scroll_top"
                href={`${URL}in-chichester${ptypeURL}${bedURL}/radius-3-miles/`}
              >
                {typeText} {searchaction} in Chichester
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", "guildford-surrey")
                  localStorage.setItem("searchValueFull", "guildford-surrey")
                }}
                className="footer_scroll_top"
                href={`${URL}in-guildford${ptypeURL}${bedURL}/radius-3-miles/`}
              >
                {typeText} {searchaction} in Guildford
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", "dorking-surrey")
                  localStorage.setItem("searchValueFull", "dorking-surrey")
                }}
                className="footer_scroll_top"
                href={`${URL}in-dorking${ptypeURL}${bedURL}/radius-3-miles/`}
              >
                {typeText} {searchaction} in Dorking
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", "crawley-west-sussex")
                  localStorage.setItem("searchValueFull", "crawley-west-sussex")
                }}
                className="footer_scroll_top"
                href={`${URL}in-crawley${ptypeURL}${bedURL}/radius-3-miles/`}
              >
                {typeText} {searchaction} in Crawley
              </a>
            </li>
          </ul>
        </Col>
      )}

      {searcharea ? (
        <Col md={6} lg={3} className="properties-info">
          {props.searchBedroomfield ? (
            <ul>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", "eastergate-west-sussex")
                    localStorage.setItem("searchValueFull", "eastergate-west-sussex")
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-eastergate-west-sussex${ptypeURL}${bedURL}/radius-3-miles/`}
                >
                  {typeText} {searchaction} in Eastergate
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", "brighton-east-sussex")
                    localStorage.setItem("searchValueFull", "brighton-east-sussex")
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-brighton${ptypeURL}${bedURL}/radius-3-miles/`}
                >
                  {typeText} {searchaction} in Brighton
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", "chichester-west-sussex")
                    localStorage.setItem("searchValueFull", "chichester-west-sussex")
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-chichester${ptypeURL}${bedURL}/radius-3-miles/`}
                >
                  {typeText} {searchaction} in Chichester
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", "guildford-surrey")
                    localStorage.setItem("searchValueFull", "guildford-surrey")
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-guildford${ptypeURL}${bedURL}/radius-3-miles/`}
                >
                  {typeText} {searchaction} in Guildford
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", "dorking-surrey")
                    localStorage.setItem("searchValueFull", "dorking-surrey")
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-dorking${ptypeURL}${bedURL}/radius-3-miles/`}
                >
                  {typeText} {searchaction} in Dorking
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", "crawley-west-sussex")
                    localStorage.setItem("searchValueFull", "crawley-west-sussex")
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-crawley${ptypeURL}${bedURL}/radius-3-miles/`}
                >
                  {typeText} {searchaction} in Crawley
                </a>
              </li>
            </ul>
          ) : props.searchPtype ? (
            <ul>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", slug_text)
                    localStorage.setItem("searchValueFull", slug_text)
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-${searcharea.replace(
                    /\s/g,
                    "-"
                  ).toLowerCase()}/1-and-more-bedrooms/radius-3-miles/`}
                >
                  1 Bedroom Property {searchaction} in{" "}
                  {capitalizeFirstLetter(searchareaText)}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", slug_text)
                    localStorage.setItem("searchValueFull", slug_text)
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-${searcharea.replace(
                    /\s/g,
                    "-"
                  ).toLowerCase()}/2-and-more-bedrooms/radius-3-miles/`}
                >
                  2 Bedroom Property {searchaction} in{" "}
                  {capitalizeFirstLetter(searchareaText)}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", slug_text)
                    localStorage.setItem("searchValueFull", slug_text)
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-${searcharea.replace(
                    /\s/g,
                    "-"
                  ).toLowerCase()}/3-and-more-bedrooms/radius-3-miles/`}
                >
                  3 Bedroom Property {searchaction} in{" "}
                  {capitalizeFirstLetter(searchareaText)}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", slug_text)
                    localStorage.setItem("searchValueFull", slug_text)
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-${searcharea.replace(
                    /\s/g,
                    "-"
                  ).toLowerCase()}/4-and-more-bedrooms/radius-3-miles/`}
                >
                  4 Bedroom Property {searchaction} in{" "}
                  {capitalizeFirstLetter(searchareaText)}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", slug_text)
                    localStorage.setItem("searchValueFull", slug_text)
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-${searcharea.replace(
                    /\s/g,
                    "-"
                  ).toLowerCase()}/5-and-more-bedrooms/radius-3-miles/`}
                >
                  5 Bedroom Property {searchaction} in{" "}
                  {capitalizeFirstLetter(searchareaText)}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", slug_text)
                    localStorage.setItem("searchValueFull", slug_text)
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-${searcharea.replace(
                    /\s/g,
                    "-"
                  ).toLowerCase()}/6-and-more-bedrooms/radius-3-miles/`}
                >
                  6 Bedroom Property {searchaction} in{" "}
                  {capitalizeFirstLetter(searchareaText)}
                </a>
              </li>
            </ul>
          ) : (
            <ul>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", slug_text)
                    localStorage.setItem("searchValueFull", slug_text)
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-${searcharea.replace(
                    /\s/g,
                    "-"
                  ).toLowerCase()}/1-and-more-bedrooms/radius-3-miles/`}
                >
                  1 Bedroom Property {searchaction} in{" "}
                  {capitalizeFirstLetter(searchareaText)}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", slug_text)
                    localStorage.setItem("searchValueFull", slug_text)
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-${searcharea.replace(
                    /\s/g,
                    "-"
                  ).toLowerCase()}/2-and-more-bedrooms/radius-3-miles/`}
                >
                  2 Bedroom Property {searchaction} in{" "}
                  {capitalizeFirstLetter(searchareaText)}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", slug_text)
                    localStorage.setItem("searchValueFull", slug_text)
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-${searcharea.replace(
                    /\s/g,
                    "-"
                  ).toLowerCase()}/3-and-more-bedrooms/radius-3-miles/`}
                >
                  3 Bedroom Property {searchaction} in{" "}
                  {capitalizeFirstLetter(searchareaText)}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", slug_text)
                    localStorage.setItem("searchValueFull", slug_text)
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-${searcharea.replace(
                    /\s/g,
                    "-"
                  ).toLowerCase()}/4-and-more-bedrooms/radius-3-miles/`}
                >
                  4 Bedroom Property {searchaction} in{" "}
                  {capitalizeFirstLetter(searchareaText)}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", slug_text)
                    localStorage.setItem("searchValueFull", slug_text)
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-${searcharea.replace(
                    /\s/g,
                    "-"
                  ).toLowerCase()}/5-and-more-bedrooms/radius-3-miles/`}
                >
                  5 Bedroom Property {searchaction} in{" "}
                  {capitalizeFirstLetter(searchareaText)}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.setItem("searchValue", slug_text)
                    localStorage.setItem("searchValueFull", slug_text)
                  }}
                  className="footer_scroll_top"
                  href={`${URL}in-${searcharea.replace(
                    /\s/g,
                    "-"
                  ).toLowerCase()}/6-and-more-bedrooms/radius-3-miles/`}
                >
                  6 Bedroom Property {searchaction} in{" "}
                  {capitalizeFirstLetter(searchareaText)}
                </a>
              </li>
            </ul>
          )}
        </Col>
      ) : (
        <Col md={6} lg={3} className="properties-info">
          <ul>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", slug_text)
                  localStorage.setItem("searchValueFull", slug_text)
                }}
                className="footer_scroll_top"
                href={`${URL}in-${searcharea.replace(
                  /\s/g,
                  "-"
                ).toLowerCase()}/1-and-more-bedrooms/radius-3-miles/`}
              >
                1 Bedroom Property {searchaction} in{" "}
                {capitalizeFirstLetter(searchareaText)}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", slug_text)
                  localStorage.setItem("searchValueFull", slug_text)
                }}
                className="footer_scroll_top"
                href={`${URL}in-${searcharea.replace(
                  /\s/g,
                  "-"
                ).toLowerCase()}/2-and-more-bedrooms/radius-3-miles/`}
              >
                2 Bedroom Property {searchaction} in{" "}
                {capitalizeFirstLetter(searchareaText)}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", searcharea)
                  localStorage.setItem("searchValueFull", searcharea)
                }}
                className="footer_scroll_top"
                href={`${URL}in-${searcharea.replace(
                  /\s/g,
                  "-"
                ).toLowerCase()}/3-and-more-bedrooms/radius-3-miles/`}
              >
                3 Bedroom Property {searchaction} in{" "}
                {capitalizeFirstLetter(searchareaText)}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", slug_text)
                  localStorage.setItem("searchValueFull", slug_text)
                }}
                className="footer_scroll_top"
                href={`${URL}in-${searcharea.replace(
                  /\s/g,
                  "-"
                ).toLowerCase()}/4-and-more-bedrooms/radius-3-miles/`}
              >
                4 Bedroom Property {searchaction} in{" "}
                {capitalizeFirstLetter(searchareaText)}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", slug_text)
                  localStorage.setItem("searchValueFull", slug_text)
                }}
                className="footer_scroll_top"
                href={`${URL}in-${searcharea.replace(
                  /\s/g,
                  "-"
                ).toLowerCase()}/5-and-more-bedrooms/radius-3-miles/`}
              >
                5 Bedroom Property {searchaction} in{" "}
                {capitalizeFirstLetter(searchareaText)}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  localStorage.setItem("searchValue", slug_text)
                  localStorage.setItem("searchValueFull", slug_text)
                }}
                className="footer_scroll_top"
                href={`${URL}in-${searcharea.replace(
                  /\s/g,
                  "-"
                ).toLowerCase()}/6-and-more-bedrooms/radius-3-miles/`}
              >
                6 Bedroom Property {searchaction} in{" "}
                {capitalizeFirstLetter(searchareaText)}
              </a>
            </li>
          </ul>
        </Col>
      )}

      <Col md={6} lg={3} className="properties-info">
        <ul>
          <li>
            <a
              onClick={() => {
                localStorage.setItem("searchValue", "billingshurst-west-sussex")
                localStorage.setItem("searchValueFull", "billingshurst-west-sussex")
              }}
              className="footer_scroll_top"
              href={`${URL}in-billingshurst-west-sussex/type-apartment/radius-3-miles/`}
            >
              Apartments {searchaction} in {area}
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                localStorage.setItem("searchValue", "billingshurst-west-sussex")
                localStorage.setItem("searchValueFull", "billingshurst-west-sussex")
              }}
              className="footer_scroll_top"
              href={`${URL}in-billingshurst-west-sussex/type-flats/radius-3-miles/`}
            >
              Flats {searchaction} in {area}
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                localStorage.setItem("searchValue", "billingshurst-west-sussex")
                localStorage.setItem("searchValueFull", "billingshurst-west-sussex")
              }}
              className="footer_scroll_top"
              href={`${URL}in-billingshurst-west-sussex/type-house/radius-3-miles/`}
            >
              Houses {searchaction} in {area}
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                localStorage.setItem("searchValue", "billingshurst-west-sussex")
                localStorage.setItem("searchValueFull", "billingshurst-west-sussex")
              }}
              className="footer_scroll_top"
              href={`${URL}in-billingshurst-west-sussex/type-bungalow`}
            >
              Bungalows {searchaction} in {area}
            </a>
          </li>
          <li>
            <a
              className="footer_scroll_top"
              href={`${MORTGAGES_PAGE_URL.alias}`}
            >
              Mortgage brokers in {capitalizeFirstLetter(searchareaText)}
            </a>
          </li>
          <li>
            <a
              className="footer_scroll_top"
              href={`${TESTIMONIALS_PAGE_URL.alias}`}
            >
              Testimonials
            </a>
          </li>
        </ul>
      </Col>

      <Col md={6} lg={3} className="properties-info">
        <ul>
          <li>
            <a
              className="footer_scroll_top"
              href={`${AREA_GUIDE_PAGE_URL.alias}`}
            >
              {capitalizeFirstLetter(searchareaText)} Area Guides
            </a>
          </li>
          <li>
            <a
              className="footer_scroll_top"
              href={`${BRANCHES_PAGE_URL.alias}/`}
            >
              Estate Agents in {capitalizeFirstLetter(searchareaText)}
            </a>
          </li>
          <li>
            <a
              className="footer_scroll_top"
              href={`${VALUATION_PAGE_URL.alias}`}
            >
              Property valuation in {capitalizeFirstLetter(searchareaText)}
            </a>
          </li>
          <li>
            <a
              className="footer_scroll_top"
              href={`${PROPERTY_MANAGEMENT_PAGE_URL.alias}`}
            >
              Property management in {capitalizeFirstLetter(searchareaText)}
            </a>
          </li>
          <li>
            <a className="footer_scroll_top" href={`${ABOUT_PAGE_URL.alias}`}>
              About Cubitt & West estate agents
            </a>
          </li>
          <li>
            <a
              className="footer_scroll_top"
              href={`${LATEST_NEWS_PAGE_URL.alias}/`}
            >
              Latest Property News
            </a>
          </li>
        </ul>
      </Col>
    </Row>
  )
}

export default PopularSearchDynamic
