import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Row, Col } from 'react-bootstrap';
import $ from 'jquery'

import { VALUATION_PAGE_URL, BRANCHES_PAGE_URL, ABOUT_PAGE_URL, PROPERTY_SERVICES_PAGE_URL, AREA_GUIDE_PAGE_URL, LATEST_NEWS_PAGE_URL, PROPERTY_MANAGEMENT_PAGE_URL, TESTIMONIALS_PAGE_URL, MORTGAGES_PAGE_URL } from '../../common/site/constants'

const PopularSearchDetails = (props) => {

  var propertydetails = props.propertydetails
  var typeText = ''
  var URL = ''
  var area = 'Billingshurst, West Sussex'
  // var ptypeURL = '/type-' + propertydetails.building

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  if (propertydetails.search_type == 'sales') {
    typeText = 'for Sale'
    URL = '/property/for-sale/'
    if (props.pagetype == 'newhomes') {
      URL = '/property/new-homes/for-sale/'
    }
    if (props.pagetype == 'commercial') {
      URL = '/property/commercial/for-sale/'
    }
  }
  if (propertydetails.search_type == 'lettings') {
    typeText = 'to Rent'
    URL = '/property/to-rent/'
    if (props.pagetype == 'newhomes') {
      URL = '/property/new-homes/to-rent/'
    }
    if (props.pagetype == 'commercial') {
      URL = '/property/commercial/for-sale/'
    }
  }

  return (
    <Row>
      <Col md={6} lg={3} className="properties-info">
        <ul>
          <li><a className="footer_scroll_top" href={`${URL}in-eastergate-west-sussex/radius-3-miles/`}>Property {typeText} in Eastergate</a></li>
          <li><a className="footer_scroll_top" href={`${URL}in-brighton-east-sussex/radius-3-miles/`}>Property {typeText} in Brighton</a></li>
          <li><a className="footer_scroll_top" href={`${URL}in-chichester-west-sussex/radius-3-miles/`}>Property {typeText} in Chichester</a></li>
          <li><a className="footer_scroll_top" href={`${URL}in-guildford-surrey/radius-3-miles/`}>Property {typeText} in Guildford</a></li>
          <li><a className="footer_scroll_top" href={`${URL}in-dorking-surrey/radius-3-miles/`}>Property {typeText} in Dorking</a></li>
          <li><a className="footer_scroll_top" href={`${URL}in-crawley-west-sussex/radius-3-miles/`}>Property {typeText} in Crawley</a></li>
        </ul>
      </Col>
      <Col md={6} lg={3} className="properties-info">
        <ul>
          <li><a className="footer_scroll_top" href={`${URL}in-${propertydetails?.address?.county ? ((propertydetails?.address?.county.replace(/\s/g, '-')).toLowerCase()).toLowerCase() : (propertydetails.address.town.replace(/\s/g, '-')).toLowerCase()}/1-and-more-bedrooms`}>1 Bedroom Property {typeText} in {propertydetails?.address?.county ? propertydetails?.address?.county : propertydetails.address.town}</a></li>
          <li><a className="footer_scroll_top" href={`${URL}in-${propertydetails?.address?.county ? (propertydetails?.address?.county.replace(/\s/g, '-')).toLowerCase() : (propertydetails.address.town.replace(/\s/g, '-')).toLowerCase()}/2-and-more-bedrooms`}>2 Bedroom Property {typeText} in {propertydetails?.address?.county ? propertydetails?.address?.county : propertydetails.address.town}</a></li>
          <li><a className="footer_scroll_top" href={`${URL}in-${propertydetails?.address?.county ? (propertydetails?.address?.county.replace(/\s/g, '-')).toLowerCase() : (propertydetails.address.town.replace(/\s/g, '-')).toLowerCase()}/3-and-more-bedrooms`}>3 Bedroom Property {typeText} in {propertydetails?.address?.county ? propertydetails?.address?.county : propertydetails.address.town}</a></li>
          <li><a className="footer_scroll_top" href={`${URL}in-${propertydetails?.address?.county ? (propertydetails?.address?.county.replace(/\s/g, '-')).toLowerCase() : (propertydetails.address.town.replace(/\s/g, '-')).toLowerCase()}/4-and-more-bedrooms`}>4 Bedroom Property {typeText} in {propertydetails?.address?.county ? propertydetails?.address?.county : propertydetails.address.town}</a></li>
          <li><a className="footer_scroll_top" href={`${URL}in-${propertydetails?.address?.county ? (propertydetails?.address?.county.replace(/\s/g, '-')).toLowerCase() : (propertydetails.address.town.replace(/\s/g, '-')).toLowerCase()}/5-and-more-bedrooms`}>5 Bedroom Property {typeText} in {propertydetails?.address?.county ? propertydetails?.address?.county : propertydetails.address.town}</a></li>
          <li><a className="footer_scroll_top" href={`${URL}in-${propertydetails?.address?.county ? (propertydetails?.address?.county.replace(/\s/g, '-')).toLowerCase() : (propertydetails.address.town.replace(/\s/g, '-')).toLowerCase()}/6-and-more-bedrooms`}>6 Bedroom Property {typeText} in {propertydetails?.address?.county ? propertydetails?.address?.county : propertydetails.address.town}</a></li>
        </ul>
      </Col>



      <Col md={6} lg={3} className="properties-info">
        <ul>
          <li><a className="footer_scroll_top" href={`${URL}in-${propertydetails?.address?.county ? propertydetails?.address?.county.replace(/\s/g, '-') : propertydetails.address.town.replace(/\s/g, '-')}/type-apartment`}>Apartments {typeText} in {propertydetails?.address?.county ? propertydetails?.address?.county : propertydetails.address.town}</a></li>
          <li><a className="footer_scroll_top" href={`${URL}in-${propertydetails?.address?.county ? propertydetails?.address?.county.replace(/\s/g, '-') : propertydetails.address.town.replace(/\s/g, '-')}/type-flats`}>Flats {typeText} in {propertydetails?.address?.county ? propertydetails?.address?.county : propertydetails.address.town}</a></li>
          <li><a className="footer_scroll_top" href={`${URL}in-${propertydetails?.address?.county ? propertydetails?.address?.county.replace(/\s/g, '-') : propertydetails.address.town.replace(/\s/g, '-')}/type-house`}>Houses {typeText} in {propertydetails?.address?.county ? propertydetails?.address?.county : propertydetails.address.town}</a></li>
          <li><a className="footer_scroll_top" href={`${URL}in-${propertydetails?.address?.county ? propertydetails?.address?.county.replace(/\s/g, '-') : propertydetails.address.town.replace(/\s/g, '-')}/type-bungalow`}>Bungalows {typeText} in {propertydetails?.address?.county ? propertydetails?.address?.county : propertydetails.address.town}</a></li>
          <li><a className="footer_scroll_top" href={`${MORTGAGES_PAGE_URL.alias}`}>Mortgage brokers in {propertydetails?.address?.county ? propertydetails?.address?.county : propertydetails.address.town}</a></li>
          <li><a className="footer_scroll_top" href={`${TESTIMONIALS_PAGE_URL.alias}`}>Testimonials</a></li>
        </ul>
      </Col>


      <Col md={6} lg={3} className="properties-info">
        <ul>
          <li><a className="footer_scroll_top" href={`${AREA_GUIDE_PAGE_URL.alias}`}>{propertydetails?.address?.county ? propertydetails?.address?.county : propertydetails.address.town} Area Guides</a></li>
          <li><a className="footer_scroll_top" href={`${BRANCHES_PAGE_URL.alias}/`}>Estate Agents in {propertydetails?.address?.county ? propertydetails?.address?.county : propertydetails.address.town}</a></li>
          <li><a className="footer_scroll_top" href={`${VALUATION_PAGE_URL.alias}`}>Property valuation in {propertydetails?.address?.county ? propertydetails?.address?.county : propertydetails.address.town}</a></li>
          <li><a className="footer_scroll_top" href={`${PROPERTY_MANAGEMENT_PAGE_URL.alias}`}>Property management in {propertydetails?.address?.county ? propertydetails?.address?.county : propertydetails.address.town}</a></li>
          <li><a className="footer_scroll_top" href={`${ABOUT_PAGE_URL.alias}`}>About Cubitt & West estate agents</a></li>
          <li><a className="footer_scroll_top" href={`${LATEST_NEWS_PAGE_URL.alias}/`}>Latest Property News</a></li>
        </ul>
      </Col>
    </Row>
  )

}

export default PopularSearchDetails;