import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import parse from 'html-react-parser';
import { Container, Row, Col, Form } from "react-bootstrap"
import ScrollAnimation from "react-animate-on-scroll";
import LogoGroup from "../../images/logo-group.svg";
import SiteLogo from "../../images/starberry-logo.png"
import Logo1 from "../../images/footer-brands/Arla_White.svg"
import Logo2 from "../../images/footer-brands/TPO-Property-Ombudsman-White.svg"
import Logo3 from "../../images/footer-brands/TSI-Trading_Standards.svg"
import "./Footer.scss";
import GenerateLink from "../common/site/generate-link"
import FooterMenu from './FooterMenu';
import SearchResultsPopularSearch from "./PopularSearch/search-results"
import PropertyDetailsPopularSearch from "./PopularSearch/property-details"
import AreaPopularSearch from "./PopularSearch/office-areaguides"

// import CookieBot from "react-cookiebot"

const Footer = (props) => {


  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      menusFooter {
        Add_Footer_Menus {
          Label
          Link {
            id
          }
          Menus {
            Label
            Link {
              id
            }
          }
        }
      }

      siteConfig {
        Youtube_Link
        Twitter_Link
        Instagram_Link
        Facebook_Link
        Footer_Bottom_Content
        Footer_Bottom_Menus {
          Label
          Link {
            id
          }
        }
        Footer_Bottom_Logos{
          alternativeText
          url
        }
        Popular_Search_Static_About
        Popular_Search_Static_Common
        Popular_Search_Static_Contact
        Popular_Search_Static_New_Homes
        Popular_Search_Static_Rent
        Popular_Search_Static_Sell
      }

    }
  }
`);


  useEffect(() => {
    const script_one = document.createElement('script');

    script_one.src = "https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
    script_one.defer = true;
    script_one.async = true;
    script_one.setAttribute("data-domain-script", "5146cd56-b127-42a8-949e-61b03579b074")

    document.body.appendChild(script_one);
    function OptanonWrapper() { }
    let yomdelElement = document.getElementById("yomdel_script")
    if (yomdelElement != null) {
      yomdelElement.remove();
    }
    try {
      (function () {

        var theScript = document.createElement("script");


        theScript.setAttribute("type", "text/javascript");
        theScript.setAttribute("id", "yomdel_script");
        theScript.setAttribute("defer", "true");
        theScript.setAttribute("src", "https://clients.yomdel.com/tools/chat_script.js?url=" + document.location.href);
        document.getElementsByTagName("body")[0].appendChild(theScript);
        theScript.onerror = function () {
        }
      })();
    } catch (error) {
      console.log('catch', error)
    }

  }, [])


  const menus = data.glstrapi.menusFooter;
  const site = data.glstrapi.siteConfig;

  const [search, setSearch] = useState(false);
  const [state, setState] = useState(menus.Add_Footer_Menus)

  const openSearch = () => {
    setSearch(!search)
  }

  const clickHandler = (index) => {
    let newState = [...state];
    let a = newState.map((item, i) => {
      if (index !== i) {
        return { ...item, isActive: false };
      } else {
        return item;
      }
    });
    console.log(state)
    if (a && a[index]) {
      let isActive = a[index].isActive;
      a[index].isActive = !isActive;
      setState(a);
    }

  }
  const year = new Date().getFullYear();

  return (

    <React.Fragment>
      <footer className="footer">
        {/* <CookieBot domainGroupId="0c554883-1151-4fd2-bc18-bfca1b507e28" /> */}
        <Container>
          <Row className="footer-block3 popular-searches">
            <div className={search ? "popular-search-block open-footer-block" : "popular-search-block"}>
              <div className="popular-search-head" onClick={openSearch}>
                <span>Popular Searches </span>
                <i className="icon-plus-footer"></i>
                <i className="icon-minus-footer"></i>
              </div>

              {props.search === "Common" || props.search == null ?
                <div className={search ? "row-block " : "row-hide"} onClick={() => localStorage.removeItem('searchValue')}>
                  {parse(site.Popular_Search_Static_Common)}
                </div>
                : props.search === "Sell" ?
                  <div className={search ? "row-block " : "row-hide"} onClick={() => localStorage.removeItem('searchValue')}>
                    {parse(site.Popular_Search_Static_Sell)}
                  </div>
                  : props.search === "Rent" ?
                    <div className={search ? "row-block " : "row-hide"} onClick={() => localStorage.removeItem('searchValue')}>
                      {parse(site.Popular_Search_Static_Rent)}
                    </div>
                    : props.search === "New_Homes" ?
                      <div className={search ? "row-block " : "row-hide"} onClick={() => localStorage.removeItem('searchValue')}>
                        {parse(site.Popular_Search_Static_New_Homes)}
                      </div>
                      : props.search === "About" ?
                        <div className={search ? "row-block " : "row-hide"} onClick={() => localStorage.removeItem('searchValue')}>
                          {parse(site.Popular_Search_Static_About)}
                        </div>
                        : props.search === "Contact" ?
                          <div className={search ? "row-block " : "row-hide"} onClick={() => localStorage.removeItem('searchValue')}>
                            {parse(site.Popular_Search_Static_Contact)}
                          </div> : props.search === "searchresults" ?
                            <div className={search ? "row-block results-popular-search" : "row-hide results-popular-search"}>
                              <SearchResultsPopularSearch {...props} />
                            </div>
                            : props.search === "propertydetails" ?
                              <div className={search ? "row-block results-popular-search" : "row-hide results-popular-search"}>
                                <PropertyDetailsPopularSearch {...props} />
                              </div>
                              : props.search === "area_office" ?
                                <div className={search ? "row-block results-popular-search" : "row-hide results-popular-search"}>
                                  <AreaPopularSearch {...props} />
                                </div>
                                : ''
              }
            </div>
          </Row>


          <div className="footer-link-block">
            <div className="footer-left">
              <Row className="footer-block1 footer-quick-links">
                {state.map((item, i) => (
                  <FooterMenu
                    i={i}
                    clickHandler={clickHandler}
                    {...item} />
                ))}
              </Row>
            </div>


          </div>
          <Row>
            <Col md="12" lg="6">
              <div className="social-icons">
                <ul>
                  {site.Facebook_Link &&
                    <li>
                      <a href={site.Facebook_Link} className="" title="facebook" target="_blank">
                        <i className="icon-facebook"></i>
                      </a>
                    </li>
                  }
                  {site.Instagram_Link &&
                    <li>
                      <a href={site.Instagram_Link} className="" title="instagram" target="_blank">
                        <i className="icon-insta"></i>
                      </a>
                    </li>
                  }
                  {site.Twitter_Link &&
                    <li>
                      <a href={site.Twitter_Link} className="" title="twitter" target="_blank">
                        <i className="icon-twitter"></i>
                      </a>
                    </li>
                  }
                  {site.Youtube_Link &&
                    <li>
                      <a href={site.Youtube_Link} className="" title="youtube" target="_blank">
                        <i className="icon-youtube"></i>
                      </a>
                    </li>
                  }
                </ul>
              </div>
            </Col>
            <Col md="12" lg="6">
              <div className="d-flex justify-content-evenly footer-bottom-logos">
                <img loading="lazy" src={Logo1} width="150" height="75" alt="" className="group-logo" />
                <img loading="lazy" src={Logo2} width="150" height="52" alt="" className="group-logo" />
                <img loading="lazy" src={Logo3} width="150" height="75" alt="" className="group-logo" />
                {/* {site.Footer_Bottom_Logos.map((logo, i) => {
                  return (

                    <img src={logo.url} alt={logo.alternativeText} className="group-logo" />

                  )
                })
                } */}
              </div>


            </Col>
          </Row>

          <Row>
            <Col md="12">
              <div className="footer-link footer-link-wrap">
                <ul>
                  {site.Footer_Bottom_Menus.map((item, i) => (
                    <li>
                      <GenerateLink link={item.Link}>{item.Label}</GenerateLink>
                      {site.Footer_Bottom_Menus.length != (i + 1) && <span class="footer-menu-link-divider">|</span>}
                    </li>
                  ))}
                </ul>
                <p className="footer-text">© {year} Arun Estates. All Rights Reserved.</p>
                <div className="footer-content">
                  {parse(site.Footer_Bottom_Content)}
                </div>
                <p>Site by <Link href="https://starberry.tv/" target="_blank" className="starberry"><img src={SiteLogo} alt="logo" width="21" height="20" />  Starberry</Link></p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer >

      {/* <CookieConsent
        disableStyles={true}
        overlay={true}
        overlayClasses="cookie-popup-bg"
        containerClasses="cookie-popup d-block"
        contentClasses=""
        buttonWrapperClasses="container accept-btn"
        buttonClasses="btn btn-secondary"
        buttonText="Accept all cookies"
        enableDeclineButton
        declineButtonText="Reject all cookies"
        declineButtonClasses="decline-btn"
        flipButtons
      >
        <Container>
          <Row>
            <Col lg={6}>
              <h3><span>Hey,</span> who wants cookies!</h3>
              <p>To Provide the best experiences, we and our partners use technologies like cookies to store or access device information. Consenting to these technologies will allow us and our partners to process personal data such as browsing behaviour or unique IDs on this site.</p>
            </Col>
            <Col lg={6}>
              <p>We use your data for:</p>
              <ul>
                <li>• Personalised ads and content measurement, audienca insights and product development</li>
                <li>• Use precise geolocation data</li>
                <li>• Actively scan device characteristics for identification</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </CookieConsent> */}

    </React.Fragment >
  )
}

export default Footer;